import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, useSprings } from '@react-spring/web';

const LoadingComponent = () => {
  const navigate = useNavigate();

  const letters = 'StrongCapitals'.split('');
  const [progress, setProgress] = useState(0);

  // Animación de las letras
  const letterSprings = useSprings(
    letters.length,
    letters.map((_, index) => ({
      from: { opacity: 0, filter: 'blur(5px)', transform: 'translateY(20px)' },
      to: { opacity: 1, filter: 'blur(0px)', transform: 'translateY(0)' },
      delay: index * 150,
      config: { tension: 180, friction: 14 },
      onRest: () => {
        const shownLetters = index + 1;
        setProgress(shownLetters / letters.length);
      },
    }))
  );

  // Animación del fondo
  const backgroundSpring = useSpring({
    from: { p: 0 },
    to: { p: progress },
    config: { duration: 300 },
  });

  // Gradiente de fondo
  const interpolatedBackground = backgroundSpring.p.to((p) => {
    // Ajusta según tus preferencias
    const R = Math.round(p * 1);
    const G = Math.round(p * 121);
    const B = Math.round(p * 111);
    const color = `rgb(${R}, ${G}, ${B})`;
    const xPos = 90 - 80 * p;

    return `radial-gradient(circle at ${xPos}% 50%, ${color}, #000000)`;
  });

  // Gradiente del texto (ajústalo para más contraste)
  const interpolatedTextGradient = backgroundSpring.p.to((p) => {
    // Colores más claros para que destaquen sobre el fondo oscuro
    return `linear-gradient(to right, #00f5cf, #02796f)`;
  });

  // Sombra dinámica, pero usando un color un poco más brillante que combine con tu verde
  const dynamicTextShadow = backgroundSpring.p.to((p) => {
    // En lugar de un color muy oscuro, podemos usar uno más claro
    return `0 0 10px rgba(0, 245, 207, 0.6)`;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/welcome');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <animated.div style={{ ...styles.container, backgroundImage: interpolatedBackground }}>
      <div style={styles.textContainer}>
        {letterSprings.map((springStyle, index) => (
          <animated.span
            key={index}
            style={{
              ...styles.letter,
              ...springStyle,
              backgroundImage: interpolatedTextGradient,
              textShadow: dynamicTextShadow,
              WebkitTextStroke: '1.5px rgba(255, 255, 255, 0.2)', // Borde sutil claro
            }}
          >
            {letters[index]}
          </animated.span>
        ))}
      </div>
    </animated.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
    padding: '1rem',
    boxSizing: 'border-box',
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  letter: {
    fontSize: 'clamp(2rem, 8vw, 4rem)',
    fontWeight: 'bold',
    letterSpacing: '2px',
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  },
};

export default LoadingComponent;
